:root {
    --max-width: 1366px;
    --header-bar-bg-color: #EEEDEB;
    --header-logo-text-color: #532531;
    --header-logo-text-size: 24px;
    --nav-bar-bg-color:#532531;
    --nav-text-color: #fff;
    --nav-hover-bg-color: #EDAA49;
    --active-color: #EDAA49;
    --font-family: Arial, sans-serif;
    --normal-font-text-size: 14px;
}

/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-family);
    background-color: var(--secondary-bg-color);
    font-size: var(--normal-font-text-size);
}

.logo-container {
    height: 131px;
    background-color: var(--header-bar-bg-color);
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.logo-text {
    color: var(--header-logo-text-color);
    font-size: var(--header-logo-text-size);
    font-weight: bold;
    max-width: 300px;
}

.logo-text-right {
    margin-left: auto;
    margin-right: 10px;
}

/* NAVIGATION */
nav {
    height: 56px;
    max-width: var(--max-width);
    margin: 0 auto;
    background-color: var(--nav-bar-bg-color);
    display: flex;
    align-items: center;
  }
  nav ul {
    list-style: none;
    text-align: center;
  }
  nav ul li {
    display: inline-block;
  }
  nav ul li a {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: var(--nav-text-color);
    font-weight: 800;
    text-transform: uppercase;
    margin: 0 10px;
  }
  nav ul li a,
  nav ul li a:after,
  nav ul li a:before {
    transition: all .5s;
  }

  nav.shift ul li a {
    position:relative;
    z-index: 1;
  }
  nav.shift ul li a:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    content: '.';
    color: transparent;
    background: var(--nav-hover-bg-color);
    visibility: none;
    opacity: 0;
    z-index: -1;
  }
  nav.shift ul li a:hover:after {
    opacity: 1;
    visibility: visible;
    height: 100%;
  }
  
  nav ul li a.active {
    background: var(--nav-hover-bg-color);
}
footer {
    max-width: var(--max-width);
    margin: 0 auto;
    background-color: var(--nav-bar-bg-color);
    color: var(--nav-text-color);
    text-align: center; /* Center the text */
    padding: 10px 0; /* Add some padding */
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}